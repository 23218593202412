import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  mainBlue,
  mainWhite,
  mainBlack,
  mainGreen,
  mainBrown,
  screen,
} from "../components/variables";
import gsap from "gsap";
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiFillFacebook,
  AiFillYoutube,
  AiFillTwitterSquare,
} from "react-icons/ai";
import Obfuscate from "react-obfuscate";
import ContactForm from "../components/contact-form";
import styled from "styled-components";

const Wrapper = styled.div`
  background: ${mainBlue};
  color: ${mainWhite};
  min-height: 100vh;
  @media ${screen.xsmall} {
    min-height: 100vh;
  }

  .intro {
    padding: 180px 0 0 0;
    max-width: 935px;
    @media ${screen.xsmall} {
      padding: 220px 0 0 0;
    }
    @media ${screen.large} {
      padding: 235px 0 0 0;
    }

    @media ${screen.xlarge} {
      padding: 265px 0 0 0;
    }

    .heading {
      font-weight: 700;
      font-size: 1.9rem;
      @media ${screen.xsmall} {
        font-size: 2.2rem;
      }
      @media ${screen.medium} {
        font-size: 2.4rem;
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  return (
    <Layout isContactPage={true}>
      <SEO
        title="404 Page"
        description="Page not found"
        webPageInfo={{
          name: "404 Page",
          url: "https://www.fmondaysmarketing.com/404/",
          description: "Page not found",
        }}
      />
      <Wrapper>
        <div className="inner-wrapper">
          <header className="intro">
            <h1 className="heading">/404 - Page not found</h1>
          </header>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ContactPage;
